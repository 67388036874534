import React from "react"

import Layout from "../components/layout"
import colors from "../components/colors"


const NotFoundPage = () => (
  <Layout
    handleLanguageChange={()=>{}}
  >
    <div style={{minHeight:'80vh', width:'100%', color:colors.textLightgrey,backgroundColor: colors.darkblue, padding:'5vw'}}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
